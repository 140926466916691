export function storeUserSettings(state, { payload: { defaultArea, defaultCollection } }) {
    return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { settings: Object.assign(Object.assign({}, state.user.settings), { defaultArea,
                defaultCollection }) }) });
}
export function storeUserAnalyticDashboardsSettings(state, { payload: analyticDashboardsSettings }) {
    return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { settings: Object.assign(Object.assign({}, state.user.settings), { analyticDashboardsSettings }) }) });
}
export function storeUserAnalyticDashboardsFilters(state, { payload: analyticsSavedFilters }) {
    return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { settings: Object.assign(Object.assign({}, state.user.settings), { analyticsSavedFilters }) }) });
}
export function storeUserAnalyticDashboardsLocationSettings(state, { payload: analyticsSelectedLocation }) {
    return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { settings: Object.assign(Object.assign({}, state.user.settings), { analyticsSelectedLocation }) }) });
}
