import { __rest } from "tslib";
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@/components/button';
import { StyledConfirmationButton, StyledDialogActions } from './confirmation.styles';
export const TITLE_ID = 'alert-title';
export const DESCRIPTION_ID = 'alert-description';
export const Confirmation = (_a) => {
    var { title, cancelLabel = 'Cancel' } = _a, _b = _a.primaryButton, _c = _b === void 0 ? {} : _b, { label: primaryLabel = 'Ok' } = _c, primaryButton = __rest(_c, ["label"]), { onCancel, secondaryButton, children, showCancel = true } = _a, props = __rest(_a, ["title", "cancelLabel", "primaryButton", "onCancel", "secondaryButton", "children", "showCancel"]);
    const hasCancelButton = showCancel && onCancel;
    return (<Dialog {...props} onClose={onCancel} aria-labelledby={TITLE_ID} aria-describedby={DESCRIPTION_ID}>
      <DialogTitle id={TITLE_ID}>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id={DESCRIPTION_ID}>{children}</DialogContentText>
      </DialogContent>

      <StyledDialogActions>
        {primaryButton.onClick && (<StyledConfirmationButton error variant="contained" {...primaryButton}>
            {primaryLabel}
          </StyledConfirmationButton>)}
        {secondaryButton && (<Button rounded variant="contained" onClick={secondaryButton.onClick} disabled={secondaryButton.disabled} tooltip={secondaryButton.tooltip}>
            {secondaryButton.label}
          </Button>)}
        {hasCancelButton
        && <Button rounded onClick={onCancel}>{cancelLabel}</Button>}
      </StyledDialogActions>

    </Dialog>);
};
export default Confirmation;
