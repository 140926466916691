import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const SeoKeywordEmptyTableIcon = (_a) => {
    var props = __rest(_a, []);
    return (<SvgIcon width="121" height="127" viewBox="0 0 121 127" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M52.6585 127C64.6337 127 74.3414 117.292 74.3414 105.317C74.3414 93.3419 64.6337 83.6341 52.6585 83.6341C40.6834 83.6341 30.9756 93.3419 30.9756 105.317C30.9756 117.292 40.6834 127 52.6585 127Z" fill="#E8EBEC"/>
    <path d="M101.6 74.3414C111.864 74.3414 120.185 66.0205 120.185 55.7561C120.185 45.4917 111.864 37.1707 101.6 37.1707C91.3355 37.1707 83.0145 45.4917 83.0145 55.7561C83.0145 66.0205 91.3355 74.3414 101.6 74.3414Z" fill="#E8EBEC"/>
    <path d="M30.9756 61.9512C48.083 61.9512 61.9512 48.083 61.9512 30.9756C61.9512 13.8683 48.083 0 30.9756 0C13.8683 0 0 13.8683 0 30.9756C0 48.083 13.8683 61.9512 30.9756 61.9512Z" fill="#E8EBEC"/>
    <path d="M16.2622 30.9755H95.25V111.512C95.25 113.216 93.8561 114.61 92.1525 114.61H19.3598C17.6561 114.61 16.2622 113.216 16.2622 111.512V30.9755Z" fill="#BDC3D8"/>
    <path d="M16.2622 30.9755H89.0549V108.415C89.0549 109.344 88.4354 109.963 87.5061 109.963H16.2622V30.9755Z" fill="white"/>
    <path d="M92.1524 115.849H19.3597C17.0366 115.849 15.0231 113.991 15.0231 111.513V30.9759C15.0231 30.3564 15.4878 29.7369 16.2622 29.7369H95.25C95.8695 29.7369 96.489 30.2015 96.489 30.9759V111.513C96.489 113.836 94.4756 115.849 92.1524 115.849ZM17.5012 32.215V111.667C17.5012 112.752 18.4304 113.681 19.5146 113.681H92.3073C93.3914 113.681 94.3206 112.752 94.3206 111.667V32.215H17.5012Z" fill="#21365F"/>
    <path d="M92.1525 15.4879H19.3598C17.6561 15.4879 16.2622 16.8818 16.2622 18.5855V30.9757H95.25V18.5855C95.25 16.8818 93.8561 15.4879 92.1525 15.4879Z" fill="#BDC3D8"/>
    <path d="M90.6037 15.4879H19.3598C17.6561 15.4879 16.2622 16.8818 16.2622 18.5855V26.3294H87.5061C89.2098 26.3294 90.6037 24.9355 90.6037 23.2318V15.4879Z" fill="white"/>
    <path d="M95.25 32.2149H16.2622C15.6427 32.2149 15.0231 31.7502 15.0231 30.9758V18.5856C15.0231 16.2624 16.8817 14.249 19.3597 14.249H92.1524C94.4756 14.249 96.489 16.1075 96.489 18.5856V30.9758C96.489 31.5954 95.8695 32.2149 95.25 32.2149ZM17.5012 29.8917H94.1658V18.5856C94.1658 17.5014 93.2365 16.5722 92.1524 16.5722H19.3597C18.2756 16.5722 17.3462 17.5014 17.3462 18.5856V29.8917H17.5012Z" fill="#21365F"/>
    <path d="M72.0183 24.7803C71.5536 24.7803 71.2439 24.6255 70.9341 24.3157C70.6243 24.006 70.4695 23.6962 70.4695 23.2316C70.4695 22.7669 70.6243 22.4572 70.9341 22.1474C71.5536 21.5279 72.6377 21.5279 73.1023 22.1474C73.4121 22.4572 73.567 22.7669 73.567 23.2316C73.567 23.6962 73.4121 24.006 73.1023 24.3157C72.7926 24.6255 72.4829 24.7803 72.0183 24.7803Z" fill="#21365F"/>
    <path d="M78.2135 23.2316C78.2135 22.4572 78.9879 21.6828 79.7623 21.6828C80.6915 21.6828 81.3111 22.4572 81.3111 23.2316C81.3111 24.006 80.6915 24.7804 79.7623 24.7804C78.9879 24.7804 78.2135 24.1609 78.2135 23.2316Z" fill="#21365F"/>
    <path d="M87.5062 24.7803C87.0415 24.7803 86.7318 24.6255 86.422 24.3157C86.1123 24.006 85.9574 23.6962 85.9574 23.2316C85.9574 22.7669 86.1123 22.4572 86.422 22.1474C87.0415 21.5279 88.1256 21.5279 88.5902 22.1474C88.9 22.4572 89.055 22.7669 89.055 23.2316C89.055 23.6962 88.9 24.006 88.5902 24.3157C88.2805 24.6255 87.9708 24.7803 87.5062 24.7803Z" fill="#21365F"/>
    <path d="M93.5919 99.817L100.601 92.808L93.4824 85.6896L86.4735 92.6985L93.5919 99.817Z" fill="#BDC3D8"/>
    <path d="M91.2232 97.4187L94.7854 93.8565C95.4049 93.237 95.4049 92.3077 94.7854 91.6882L91.0683 87.9711L86.422 92.6175L91.2232 97.4187Z" fill="white"/>
    <path d="M93.5463 100.981C93.2365 100.981 92.9268 100.826 92.7719 100.671L85.6475 93.5467C85.1829 93.082 85.1829 92.3076 85.6475 91.843L92.6171 84.8735C93.0817 84.4089 93.856 84.4089 94.3207 84.8735L101.445 91.9979C101.91 92.4625 101.91 93.2369 101.445 93.7015L94.4756 100.671C94.1659 100.826 93.856 100.981 93.5463 100.981ZM88.1256 92.6174L93.7012 98.193L98.967 92.9271L93.3914 87.3516L88.1256 92.6174Z" fill="#21365F"/>
    <path d="M101.866 111.327L112.16 101.033L102.194 91.0672L91.8999 101.362L101.866 111.327Z" fill="#BDC3D8"/>
    <path d="M98.8121 108.26L103.768 103.304C105.007 102.065 105.007 100.207 103.768 98.9678L99.1219 94.3214L91.9974 101.446L98.8121 108.26Z" fill="white"/>
    <path d="M101.91 112.597C101.6 112.597 101.29 112.442 101.135 112.287L91.2232 102.375C90.7586 101.91 90.7586 101.136 91.2232 100.671L101.445 90.4494C101.91 89.9847 102.684 89.9847 103.149 90.4494L113.061 100.362C113.216 100.516 113.371 100.826 113.371 101.136C113.371 101.446 113.216 101.755 113.061 101.91L102.839 112.132C102.529 112.442 102.22 112.597 101.91 112.597ZM93.5464 101.446L101.91 109.809L110.583 101.136L102.22 92.7725L93.5464 101.446Z" fill="#21365F"/>
    <path d="M110.583 113.216L113.99 109.809C115.849 107.951 115.849 104.853 113.99 102.994L112.132 101.136L101.91 111.358L103.768 113.216C105.627 115.075 108.725 115.075 110.583 113.216Z" fill="white"/>
    <path d="M109.034 104.233L110.893 106.092C112.751 107.951 112.751 111.048 110.893 112.907L110.583 113.216L113.99 109.809C115.849 107.951 115.849 104.853 113.99 102.994L112.132 101.136L109.034 104.233Z" fill="#BDC3D8"/>
    <path d="M107.176 115.849C105.627 115.849 104.078 115.23 102.994 114.145L101.135 112.287C100.981 112.132 100.826 111.822 100.826 111.513C100.826 111.203 100.981 110.893 101.135 110.738L111.357 100.516C111.822 100.052 112.596 100.052 113.061 100.516L114.92 102.375C117.243 104.698 117.243 108.57 114.92 110.893L111.512 114.3C110.273 115.23 108.724 115.849 107.176 115.849ZM103.613 111.358L104.698 112.442C106.092 113.836 108.415 113.836 109.964 112.442L113.371 109.035C114.765 107.641 114.765 105.317 113.371 103.769L112.287 102.685L103.613 111.358Z" fill="#21365F"/>
    <path d="M74.0318 99.5869C88.573 99.5869 100.361 87.7988 100.361 73.2576C100.361 58.7163 88.573 46.9283 74.0318 46.9283C59.4905 46.9283 47.7025 58.7163 47.7025 73.2576C47.7025 87.7988 59.4905 99.5869 74.0318 99.5869Z" fill="white"/>
    <path d="M90.6037 52.8135C94.3207 57.305 96.489 63.0355 96.489 69.3855C96.489 83.944 84.7183 95.7148 70.1597 95.7148C63.8097 95.7148 58.0792 93.5465 53.5878 89.8294C58.389 95.7148 65.8232 99.5867 74.0317 99.5867C88.5902 99.5867 100.361 87.816 100.361 73.2575C100.361 65.0489 96.6439 57.6148 90.6037 52.8135Z" fill="#BDC3D8"/>
    <path d="M74.0318 100.826C58.8537 100.826 46.4635 88.4353 46.4635 73.2573C46.4635 58.0792 58.8537 45.6889 74.0318 45.6889C89.2098 45.6889 101.6 58.0792 101.6 73.2573C101.6 88.4353 89.2098 100.826 74.0318 100.826ZM74.0318 48.167C60.0928 48.167 48.7867 59.4731 48.7867 73.4122C48.7867 87.3512 60.0928 98.6573 74.0318 98.6573C87.9708 98.6573 99.2769 87.3512 99.2769 73.4122C99.2769 59.4731 87.9708 48.167 74.0318 48.167Z" fill="#21365F"/>
    <path d="M81.0013 87.6611H67.0623C63.655 87.6611 60.8672 84.8733 60.8672 81.4659V69.0757C60.8672 68.1464 61.4867 67.5269 62.416 67.5269H85.6477C86.5769 67.5269 87.1965 68.1464 87.1965 69.0757V81.4659C87.1965 84.8733 84.5635 87.6611 81.0013 87.6611Z" fill="#BDC3D8"/>
    <path d="M87.1964 81.4659H67.0623C65.3586 81.4659 63.9647 80.072 63.9647 78.3684V67.5269H85.6476C86.5769 67.5269 87.1964 68.1464 87.1964 69.0757V81.4659Z" fill="white"/>
    <path d="M81.0012 88.9005H67.0622C63.0354 88.9005 59.6281 85.6481 59.6281 81.4663V69.0761C59.6281 67.5273 60.8671 66.2883 62.4158 66.2883H85.6475C87.1963 66.2883 88.4353 67.5273 88.4353 69.0761V81.4663C88.4353 85.6481 85.1829 88.9005 81.0012 88.9005ZM62.4158 68.7664C62.261 68.7664 61.9512 68.9212 61.9512 69.231V81.6212C61.9512 84.409 64.2744 86.7322 67.0622 86.7322H81.0012C83.789 86.7322 86.1122 84.409 86.1122 81.6212V69.231C86.1122 69.0761 85.9573 68.7664 85.6475 68.7664H62.4158Z" fill="#21365F"/>
    <path d="M81.0014 73.4127C80.3818 73.4127 79.7624 72.948 79.7624 72.1736V62.8809C79.7624 61.0224 78.2135 59.3187 76.2001 59.3187H71.5537C69.6952 59.3187 67.9916 60.8675 67.9916 62.8809V72.1736C67.9916 72.7931 67.5269 73.4127 66.7526 73.4127C65.9782 73.4127 65.5135 72.948 65.5135 72.1736V62.8809C65.5135 59.6285 68.1465 56.9956 71.3989 56.9956H76.0452C79.2977 56.9956 81.9306 59.6285 81.9306 62.8809V72.1736C82.2403 72.948 81.7757 73.4127 81.0014 73.4127Z" fill="#21365F"/>
    <path d="M37.9452 55.7561H28.6525C26.9488 55.7561 25.5549 54.3622 25.5549 52.6585V43.3658C25.5549 41.6622 26.9488 40.2683 28.6525 40.2683H37.9452C39.6488 40.2683 41.0427 41.6622 41.0427 43.3658V52.6585C41.0427 54.3622 39.6488 55.7561 37.9452 55.7561Z" fill="#BDC3D8"/>
    <path d="M34.8476 51.1097H25.5549V43.3658C25.5549 41.6622 26.9488 40.2683 28.6525 40.2683H36.3964V49.561C36.3964 50.4902 35.7769 51.1097 34.8476 51.1097Z" fill="white"/>
    <path d="M37.9451 56.9955H28.6525C26.3293 56.9955 24.3158 55.1369 24.3158 52.6589V43.3662C24.3158 41.043 26.1744 39.0296 28.6525 39.0296H37.9451C40.2683 39.0296 42.2817 40.8882 42.2817 43.3662V52.6589C42.2817 54.9821 40.2683 56.9955 37.9451 56.9955ZM28.6525 41.5077C27.5683 41.5077 26.639 42.437 26.639 43.5211V52.8138C26.639 53.8979 27.5683 54.8272 28.6525 54.8272H37.9451C39.0293 54.8272 39.9585 53.8979 39.9585 52.8138V43.5211C39.9585 42.437 39.0293 41.5077 37.9451 41.5077H28.6525Z" fill="#21365F"/>
    <path d="M37.9452 80.5365H28.6525C26.9488 80.5365 25.5549 79.1426 25.5549 77.4389V68.1462C25.5549 66.4426 26.9488 65.0487 28.6525 65.0487H37.9452C39.6488 65.0487 41.0427 66.4426 41.0427 68.1462V77.4389C41.0427 79.1426 39.6488 80.5365 37.9452 80.5365Z" fill="#BDC3D8"/>
    <path d="M34.8476 75.8901H25.5549V68.1462C25.5549 66.4426 26.9488 65.0487 28.6525 65.0487H36.3964V74.3413C36.3964 75.2706 35.7769 75.8901 34.8476 75.8901Z" fill="white"/>
    <path d="M37.9451 81.7759H28.6525C26.3293 81.7759 24.3158 79.9173 24.3158 77.4393V68.1466C24.3158 65.8234 26.1744 63.81 28.6525 63.81H37.9451C40.2683 63.81 42.2817 65.6685 42.2817 68.1466V77.4393C42.2817 79.7625 40.2683 81.7759 37.9451 81.7759ZM28.6525 66.2881C27.5683 66.2881 26.639 67.2173 26.639 68.3015V77.5942C26.639 78.6783 27.5683 79.6076 28.6525 79.6076H37.9451C39.0293 79.6076 39.9585 78.6783 39.9585 77.5942V68.3015C39.9585 67.2173 39.0293 66.2881 37.9451 66.2881H28.6525Z" fill="#21365F"/>
    <path d="M37.9452 105.317H28.6525C26.9488 105.317 25.5549 103.923 25.5549 102.22V92.9268C25.5549 91.2232 26.9488 89.8293 28.6525 89.8293H37.9452C39.6488 89.8293 41.0427 91.2232 41.0427 92.9268V102.22C41.0427 103.923 39.6488 105.317 37.9452 105.317Z" fill="#BDC3D8"/>
    <path d="M34.8476 100.671H25.5549V92.9268C25.5549 91.2232 26.9488 89.8293 28.6525 89.8293H36.3964V99.122C36.3964 100.051 35.7769 100.671 34.8476 100.671Z" fill="white"/>
    <path d="M37.9451 106.556H28.6525C26.3293 106.556 24.3158 104.698 24.3158 102.22V92.927C24.3158 90.6038 26.1744 88.5904 28.6525 88.5904H37.9451C40.2683 88.5904 42.2817 90.4489 42.2817 92.927V102.22C42.2817 104.543 40.2683 106.556 37.9451 106.556ZM28.6525 91.0685C27.5683 91.0685 26.639 91.9977 26.639 93.0819V102.375C26.639 103.459 27.5683 104.388 28.6525 104.388H37.9451C39.0293 104.388 39.9585 103.459 39.9585 102.375V93.0819C39.9585 91.9977 39.0293 91.0685 37.9451 91.0685H28.6525Z" fill="#21365F"/>
    <path d="M85.9574 41.5077H74.3415C73.722 41.5077 73.1024 41.043 73.1024 40.2687C73.1024 39.4943 73.5671 39.0296 74.3415 39.0296H85.9574C86.5769 39.0296 87.1964 39.4943 87.1964 40.2687C87.1964 41.043 86.5769 41.5077 85.9574 41.5077Z" fill="#21365F"/>
    <path d="M68.1464 41.5077H50.3354C49.7159 41.5077 49.0963 41.043 49.0963 40.2687C49.0963 39.4943 49.561 39.0296 50.3354 39.0296H68.1464C68.7659 39.0296 69.3854 39.4943 69.3854 40.2687C69.3854 41.043 68.7659 41.5077 68.1464 41.5077Z" fill="#21365F"/>
    <path d="M55.7561 49.2516H50.3354C49.7159 49.2516 49.0963 48.7869 49.0963 48.0125C49.0963 47.2381 49.561 46.7735 50.3354 46.7735H55.7561C56.3757 46.7735 56.9952 47.2381 56.9952 48.0125C56.9952 48.7869 56.3757 49.2516 55.7561 49.2516Z" fill="#21365F"/>
    <path d="M55.7561 98.8126H50.3354C49.7159 98.8126 49.0963 98.348 49.0963 97.5736C49.0963 96.7992 49.561 96.3346 50.3354 96.3346H55.7561C56.3757 96.3346 56.9952 96.7992 56.9952 97.5736C56.9952 98.348 56.3757 98.8126 55.7561 98.8126Z" fill="#21365F"/>
    <path d="M77.4391 106.556H50.3354C49.7159 106.556 49.0963 106.092 49.0963 105.317C49.0963 104.543 49.561 104.078 50.3354 104.078H77.4391C78.0586 104.078 78.6781 104.543 78.6781 105.317C78.6781 106.092 78.0586 106.556 77.4391 106.556Z" fill="#21365F"/>
    <path d="M107.64 27.5685H101.445C100.826 27.5685 100.206 27.1039 100.206 26.3295C100.206 25.5551 100.671 25.0905 101.445 25.0905H107.64C108.26 25.0905 108.879 25.5551 108.879 26.3295C108.879 27.1039 108.26 27.5685 107.64 27.5685Z" fill="#21365F"/>
    <path d="M104.543 30.6661C103.923 30.6661 103.304 30.2014 103.304 29.427V23.2319C103.304 22.6124 103.768 21.9929 104.543 21.9929C105.317 21.9929 105.782 22.4575 105.782 23.2319V29.427C105.782 30.0465 105.162 30.6661 104.543 30.6661Z" fill="#21365F"/>
    <path d="M104.543 36.8613C105.227 36.8613 105.782 36.3065 105.782 35.6222C105.782 34.9379 105.227 34.3832 104.543 34.3832C103.858 34.3832 103.304 34.9379 103.304 35.6222C103.304 36.3065 103.858 36.8613 104.543 36.8613Z" fill="#21365F"/>
    <path d="M104.543 41.5077C105.227 41.5077 105.782 40.9529 105.782 40.2687C105.782 39.5844 105.227 39.0296 104.543 39.0296C103.858 39.0296 103.304 39.5844 103.304 40.2687C103.304 40.9529 103.858 41.5077 104.543 41.5077Z" fill="#21365F"/>
    <path d="M104.543 49.2516C105.227 49.2516 105.782 48.6968 105.782 48.0125C105.782 47.3282 105.227 46.7735 104.543 46.7735C103.858 46.7735 103.304 47.3282 103.304 48.0125C103.304 48.6968 103.858 49.2516 104.543 49.2516Z" fill="#21365F"/>
    <path d="M104.543 58.5443C105.227 58.5443 105.782 57.9896 105.782 57.3053C105.782 56.621 105.227 56.0663 104.543 56.0663C103.858 56.0663 103.304 56.621 103.304 57.3053C103.304 57.9896 103.858 58.5443 104.543 58.5443Z" fill="#21365F"/>
    <path d="M115.384 78.214H109.189C108.57 78.214 107.95 77.7494 107.95 76.975C107.95 76.2006 108.415 75.736 109.189 75.736H115.384C116.004 75.736 116.623 76.2006 116.623 76.975C116.623 77.7494 116.004 78.214 115.384 78.214Z" fill="#21365F"/>
    <path d="M112.287 81.3115C111.667 81.3115 111.047 80.8469 111.047 80.0725V73.8774C111.047 73.2579 111.512 72.6384 112.287 72.6384C113.061 72.6384 113.526 73.103 113.526 73.8774V80.0725C113.526 80.8469 112.906 81.3115 112.287 81.3115Z" fill="#21365F"/>
    <path d="M112.287 69.0757C112.971 69.0757 113.526 68.521 113.526 67.8367C113.526 67.1524 112.971 66.5976 112.287 66.5976C111.602 66.5976 111.047 67.1524 111.047 67.8367C111.047 68.521 111.602 69.0757 112.287 69.0757Z" fill="#21365F"/>
    <path d="M112.287 62.8806C112.971 62.8806 113.526 62.3258 113.526 61.6415C113.526 60.9573 112.971 60.4025 112.287 60.4025C111.602 60.4025 111.047 60.9573 111.047 61.6415C111.047 62.3258 111.602 62.8806 112.287 62.8806Z" fill="#21365F"/>
    <path d="M112.287 55.1366C112.971 55.1366 113.526 54.5819 113.526 53.8976C113.526 53.2133 112.971 52.6586 112.287 52.6586C111.602 52.6586 111.047 53.2133 111.047 53.8976C111.047 54.5819 111.602 55.1366 112.287 55.1366Z" fill="#21365F"/>
  </SvgIcon>);
};
export default SeoKeywordEmptyTableIcon;
