import React, { useState } from 'react';
import { baseColors, ButtonV2, Icon, ToggleButtonGroup, } from '@groupby/ui-components';
import { Button } from '@/components/button';
import Select from '@/components/controls/select';
import { useLocalization } from '@/localization';
import { AdvancedFilterBy } from '../table-filter.models';
import { StyledAdvancedFilter, StyledAnd, StyledItemWrapper } from './advanced-filter.styles';
export const AdvancedFilter = ({ isAndOrShown, isAddButtonShown, hasAndOrToggle, selectedFilterBy, onChangeFilterBy, filterOptionOptions, selectedFilterOption, onChangeFilterOption, filterInput, removeAdvancedFilter, addAdvancedFilter, className, }) => {
    const [toggleValue, setToggleValue] = useState('and');
    const { formatMessage: t } = useLocalization();
    const handleToggleChange = (e) => setToggleValue(e.currentTarget.value);
    const renderFilterAdjoinElement = () => {
        if (hasAndOrToggle) {
            return (<ToggleButtonGroup className="and-or-toggle" radioGroupMode exclusive options={[
                { value: 'and', title: t({ key: 'AND' }) },
                { value: 'or', title: t({ key: 'OR' }) },
            ]} minWidth="4.375rem" value={toggleValue} onChange={handleToggleChange}/>);
        }
        return (<StyledAnd>
        {t({ key: 'AND' })}
      </StyledAnd>);
    };
    return (<StyledAdvancedFilter className={className}>

      <StyledItemWrapper>
        <Select disableUnderline selected={selectedFilterBy} onChange={onChangeFilterBy} options={[
        { label: t({ key: 'TAG' }), value: AdvancedFilterBy.TAG },
        { label: t({ key: 'NAME' }), value: AdvancedFilterBy.NAME },
        { label: t({ key: 'DATE_CREATED' }), value: AdvancedFilterBy.DATE_CREATED },
        { label: t({ key: 'DATE_MODIFIED' }), value: AdvancedFilterBy.DATE_MODIFIED },
    ]}/>
      </StyledItemWrapper>

      <StyledItemWrapper>
        <Select disableUnderline selected={selectedFilterOption} onChange={onChangeFilterOption} options={filterOptionOptions}/>
      </StyledItemWrapper>

      {filterInput}

      <StyledItemWrapper>
        <ButtonV2 error onClick={removeAdvancedFilter} icon={<Icon variant="delete"/>}/>
      </StyledItemWrapper>

      {isAndOrShown && renderFilterAdjoinElement()}

      {isAddButtonShown && (<Button rounded variant="outlined" startIcon={<Icon variant="addRounded" color={baseColors.black}/>} title={t({ key: 'ADD_FILTER' })} onClick={addAdvancedFilter}/>)}

    </StyledAdvancedFilter>);
};
export default AdvancedFilter;
