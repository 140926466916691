import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const SeoSitemapEmptyTableIcon = (_a) => {
    var props = __rest(_a, []);
    return (<SvgIcon width="97" height="98" viewBox="0 0 97 98" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M79.0727 40.7344C88.9737 40.7344 97 32.7081 97 22.807C97 12.906 88.9737 4.87964 79.0727 4.87964C69.1716 4.87964 61.1453 12.906 61.1453 22.807C61.1453 32.7081 69.1716 40.7344 79.0727 40.7344Z" fill="#D7DCE5"/>
    <path d="M35.022 97.3977C43.5086 97.3977 50.3883 90.518 50.3883 82.0314C50.3883 73.5448 43.5086 66.665 35.022 66.665C26.5354 66.665 19.6556 73.5448 19.6556 82.0314C19.6556 90.518 26.5354 97.3977 35.022 97.3977Z" fill="#D7DCE5"/>
    <path d="M25.6106 51.5932C39.7549 51.5932 51.2211 40.127 51.2211 25.9826C51.2211 11.8383 39.7549 0.37207 25.6106 0.37207C11.4662 0.37207 0 11.8383 0 25.9826C0 40.127 11.4662 51.5932 25.6106 51.5932Z" fill="#D7DCE5"/>
    <path d="M24.894 29.5303H9.92458C8.5032 29.5303 7.36353 30.6827 7.36353 32.0913V37.2134C7.36353 37.2134 18.0304 35.1902 21.0268 44.6789C22.525 49.4424 21.5646 55.1152 20.5529 60.3782L34.0754 63.1313L24.9068 29.543L24.894 29.5303Z" fill="white"/>
    <path d="M34.0623 63.1313L24.8937 29.543H20.2325L27.6469 56.7286L21.3722 55.4609C21.1417 57.1128 20.8473 58.7775 20.5271 60.3909L34.0495 63.1441L34.0623 63.1313Z" fill="#C3CEFF"/>
    <path d="M34.0629 64.0916C34.0629 64.0916 33.9348 64.0916 33.8708 64.0788L20.3484 61.3384C19.8362 61.236 19.4904 60.7366 19.5929 60.2116C20.6557 54.7181 21.5009 49.4295 20.0923 44.9861C17.4032 36.4322 7.92728 38.0969 7.53031 38.1737C7.2486 38.225 6.95408 38.1481 6.73639 37.9688C6.5187 37.7895 6.39062 37.5206 6.39062 37.2261V32.104C6.39062 30.1576 7.96567 28.5825 9.91208 28.5825H24.8943C25.3297 28.5825 25.701 28.8771 25.8163 29.2869L34.9848 62.8751C35.0745 63.1952 34.9848 63.541 34.7544 63.7843C34.5751 63.9764 34.319 64.0916 34.0501 64.0916H34.0629ZM21.6674 59.6353L32.7311 61.8762L24.1772 30.4906H9.93771C9.05415 30.4906 8.33705 31.2077 8.33705 32.0912V36.1377C11.3207 35.9072 19.3496 36.1249 21.9491 44.3971C23.3961 48.9942 22.6917 54.2187 21.6801 59.6353H21.6674Z" fill="#081E42"/>
    <path d="M71.0064 70.6352V53.6426V29.5303H24.8945L34.0632 63.1313L71.0064 70.6352Z" fill="#C3CEFF"/>
    <path d="M62.8106 63.6051C64.3984 63.9252 65.8839 62.7087 65.8839 61.0952V29.5303H24.907L32.5261 57.4458L62.8234 63.6051H62.8106Z" fill="white"/>
    <path d="M71.0061 71.5955C71.0061 71.5955 70.878 71.5955 70.8139 71.5699L33.8707 64.066C33.5122 63.9891 33.2305 63.7331 33.1408 63.3745L23.9723 29.7862C23.8954 29.4917 23.9595 29.1844 24.1388 28.9539C24.318 28.7106 24.5997 28.5825 24.907 28.5825H71.0061C71.5311 28.5825 71.9664 29.0179 71.9664 29.5429V70.6351C71.9664 70.9168 71.8384 71.1985 71.6079 71.3778C71.4415 71.5187 71.2237 71.5955 71.0061 71.5955ZM34.8311 62.3117L70.0457 69.4698V30.5033H26.1619L34.8311 62.3117Z" fill="#081E42"/>
    <path d="M20.541 60.3778C18.6971 69.8793 16.6738 78.0235 28.6467 76.9095C31.9633 76.6021 34.9726 76.4997 37.7257 76.5509L34.0634 63.1182L20.541 60.365V60.3778Z" fill="white"/>
    <path d="M34.0625 63.1313L28.7611 62.0557L30.6563 69.0089C30.9892 70.2254 30.0929 71.4163 28.838 71.4291C26.7891 71.4547 24.5866 71.57 22.2432 71.7877C21.1292 71.8902 20.1432 71.9158 19.2596 71.8646C19.7975 75.4116 22.1792 77.5117 28.6459 76.9098C31.9624 76.6025 34.9717 76.5 37.7248 76.5513L34.0625 63.1185V63.1313Z" fill="#C3CEFF"/>
    <path d="M26.61 77.9723C23.5111 77.9723 21.3086 77.2168 19.9256 75.6802C17.1725 72.6326 18.2097 67.344 19.4006 61.2103L19.6055 60.1858C19.6567 59.9297 19.7976 59.712 20.0152 59.5711C20.2329 59.4303 20.489 59.3791 20.7452 59.4303L34.2675 62.1707C34.6261 62.2475 34.9078 62.5036 34.9974 62.8621L38.6597 76.2948C38.7366 76.5894 38.6725 76.8967 38.4933 77.14C38.3012 77.3833 38.0067 77.5114 37.7122 77.5114C34.8694 77.4601 31.8473 77.5754 28.7485 77.8571C27.9929 77.9211 27.2887 77.9595 26.6228 77.9595L26.61 77.9723ZM21.2958 61.5176V61.5944C20.2329 66.9854 19.2342 72.0691 21.347 74.4125C22.5892 75.7955 24.9581 76.2948 28.5563 75.9747C31.2711 75.7186 33.9218 75.629 36.4573 75.6034L33.2815 63.9633L21.2958 61.5304V61.5176Z" fill="#081E42"/>
    <path d="M71.006 85.8732H86.7565C88.165 85.8732 89.3175 84.7207 89.3175 83.3121V74.3485L71.006 70.6221V85.8732Z" fill="#C3CEFF"/>
    <path d="M71.006 82.0317H81.6343C83.0429 82.0317 84.1954 80.8921 84.1954 79.4707V73.3113L71.006 70.635V82.0317Z" fill="white"/>
    <path d="M86.7565 86.8336H71.0061C70.481 86.8336 70.0457 86.3983 70.0457 85.8732V70.6221C70.0457 70.3404 70.1737 70.0587 70.4042 69.8794C70.6219 69.7001 70.9164 69.6233 71.1982 69.6873L89.5097 73.4008C89.9579 73.4905 90.278 73.8875 90.278 74.3485V83.3122C90.278 85.2586 88.7029 86.8336 86.7565 86.8336ZM71.9664 84.9128H86.7565C87.6401 84.9128 88.3572 84.1957 88.3572 83.3122V75.1296L71.9664 71.8002V84.9128Z" fill="#081E42"/>
    <path d="M86.7565 29.5303H71.006V53.6426H89.3175V32.0913C89.3175 30.6827 88.165 29.5303 86.7565 29.5303Z" fill="#C3CEFF"/>
    <path d="M84.1954 29.5303H71.006V48.5204H81.6343C83.0429 48.5204 84.1954 47.368 84.1954 45.9594V29.5303Z" fill="white"/>
    <path d="M89.3176 54.6031H71.0061C70.481 54.6031 70.0457 54.1678 70.0457 53.6428V29.5305C70.0457 29.0054 70.481 28.5701 71.0061 28.5701H86.7565C88.7029 28.5701 90.278 30.1451 90.278 32.0915V53.6428C90.278 54.1678 89.8426 54.6031 89.3176 54.6031ZM71.9664 52.6824H88.3572V32.0915C88.3572 31.208 87.6401 30.4909 86.7565 30.4909H71.9664V52.6824Z" fill="#081E42"/>
    <path d="M71.006 70.635L89.3175 74.3485V53.6423H71.006V70.635Z" fill="#C3CEFF"/>
    <path d="M71.006 65.2697L81.1222 67.3313C82.71 67.6514 84.1954 66.435 84.1954 64.8215V53.6553H71.006V65.2825V65.2697Z" fill="white"/>
    <path d="M89.3176 75.309C89.3176 75.309 89.1896 75.309 89.1256 75.2962L70.8139 71.5698C70.3658 71.4802 70.0457 71.0832 70.0457 70.635V53.6552C70.0457 53.1302 70.481 52.6948 71.0061 52.6948H89.3176C89.8426 52.6948 90.278 53.1302 90.278 53.6552V74.3613C90.278 74.6559 90.15 74.9248 89.9195 75.1041C89.753 75.2449 89.5353 75.3217 89.3176 75.3217V75.309ZM71.9664 69.8411L88.3572 73.1705V54.6028H71.9664V69.8411Z" fill="#081E42"/>
    <path d="M28.6463 76.9097C10.0402 78.6256 25.2273 58.0602 21.0144 44.6787C18.0307 35.1772 7.35107 37.2133 7.35107 37.2133V83.3123C7.35107 84.7209 8.49074 85.8734 9.91213 85.8734H63.054C63.054 85.8734 56.3313 74.3486 28.6463 76.9097Z" fill="white"/>
    <path d="M17.1724 48.52C21.3854 61.9016 6.19824 82.4669 24.8043 80.751C41.5152 79.2016 50.5814 82.787 55.1528 85.8731H63.0665C63.0665 85.8731 56.3437 74.3484 28.6587 76.9094C10.0526 78.6253 25.2398 58.06 21.0268 44.6785C18.0432 35.1769 7.36353 37.213 7.36353 37.213V40.9522C10.7313 41.2595 15.3669 42.7705 17.1852 48.5329L17.1724 48.52Z" fill="#C3CEFF"/>
    <path d="M63.0664 86.8335H9.92453C7.97813 86.8335 6.40308 85.2585 6.40308 83.3121V37.213C6.40308 36.7521 6.73607 36.355 7.18426 36.2654C7.29951 36.2398 18.7346 34.191 21.9359 44.384C23.6006 49.6982 22.3713 56.0112 21.2828 61.5815C20.2328 66.9725 19.234 72.0562 21.3469 74.3867C22.589 75.7697 24.9579 76.2691 28.5562 75.9362C56.5357 73.3495 63.6043 84.8743 63.886 85.3737C64.0653 85.6682 64.0653 86.0396 63.886 86.3341C63.7195 86.6286 63.3994 86.8207 63.0536 86.8207L63.0664 86.8335ZM8.32387 38.0453V83.3121C8.32387 84.1956 9.04096 84.9127 9.92453 84.9127H61.0048C58.0724 82.0187 49.3264 75.9618 28.7355 77.8698C24.4841 78.2668 21.6029 77.5497 19.9254 75.6929C17.1723 72.6452 18.2096 67.3567 19.4005 61.2229C20.4505 55.8703 21.6286 49.8134 20.1047 44.973C17.8254 37.7509 10.7185 37.8149 8.32387 38.0582V38.0453Z" fill="#081E42"/>
    <path d="M34.0632 63.1311L37.7255 76.5638C57.8554 76.9608 63.0671 85.886 63.0671 85.886H71.0064V70.635L34.0632 63.1311Z" fill="white"/>
    <path d="M65.8842 69.585V76.9096C65.8842 78.331 64.7445 79.4707 63.3231 79.4707H54.0778C60.9286 82.211 63.0798 85.8733 63.0798 85.8733H71.0191V70.6222L65.897 69.585H65.8842Z" fill="#C3CEFF"/>
    <path d="M71.0064 86.8336H63.0671C62.7214 86.8336 62.4141 86.6542 62.2348 86.3597C62.1836 86.2829 56.9462 77.8955 37.6999 77.5241C37.2773 77.5241 36.906 77.2296 36.7907 76.8198L33.1284 63.387C33.0387 63.0669 33.1283 62.7212 33.3588 62.4779C33.5893 62.2346 33.9223 62.1321 34.2552 62.1961L71.1985 69.7001C71.6467 69.7897 71.9668 70.1867 71.9668 70.6349V85.8859C71.9668 86.4109 71.5314 86.8463 71.0064 86.8463V86.8336ZM63.5794 84.9128H70.046V71.416L35.4077 64.3731L38.4682 75.6161C55.909 76.1411 62.1708 83.0048 63.5794 84.9128Z" fill="#081E42"/>
    <path d="M72.1335 10.7572C63.0162 1.63985 48.2388 1.63985 39.1343 10.7572C30.0169 19.8746 30.0169 34.6519 39.1343 43.7564L55.6403 60.2625L72.1463 43.7564C81.2637 34.6391 81.2637 19.8618 72.1463 10.7572H72.1335Z" fill="white"/>
    <path d="M72.1333 10.7575C71.2625 9.88669 70.3277 9.11835 69.3545 8.41406C76.0133 17.5442 75.2578 30.4008 67.0112 38.6346L50.5051 55.1406L55.6272 60.2627L72.1333 43.7567C81.2506 34.6393 81.2506 19.862 72.1333 10.7575Z" fill="#C3CEFF"/>
    <path d="M55.64 61.2231C55.3839 61.2231 55.1406 61.1207 54.9613 60.9414L38.4553 44.4354C28.9794 34.9595 28.9794 19.542 38.4553 10.0789C47.9312 0.602947 63.3488 0.602947 72.8119 10.0789C77.3962 14.6631 79.9316 20.7712 79.9316 27.2635C79.9316 33.7558 77.409 39.8511 72.8119 44.4482L56.3059 60.9542C56.1266 61.1335 55.8833 61.2359 55.6272 61.2359L55.64 61.2231ZM55.64 4.89265C49.9032 4.89265 44.1793 7.06961 39.8127 11.4362C31.0923 20.1566 31.0923 34.3576 39.8127 43.078L55.64 58.9053L71.4673 43.078C75.693 38.8523 78.0236 33.2308 78.0236 27.2507C78.0236 21.2706 75.693 15.6491 71.4673 11.4234C67.1007 7.05679 61.3767 4.87991 55.64 4.87991V4.89265Z" fill="#081E42"/>
    <path d="M55.6396 40.0686C62.7118 40.0686 68.4449 34.3354 68.4449 27.2633C68.4449 20.1911 62.7118 14.458 55.6396 14.458C48.5675 14.458 42.8344 20.1911 42.8344 27.2633C42.8344 34.3354 48.5675 40.0686 55.6396 40.0686Z" fill="white"/>
    <path d="M64.2059 17.7617C64.4492 18.7477 64.6029 19.7849 64.6029 20.8478C64.6029 27.9163 58.8661 33.653 51.7976 33.653C48.4938 33.653 45.4974 32.3981 43.2308 30.3364C44.6138 35.9067 49.6463 40.0557 55.652 40.0557C62.7205 40.0557 68.4573 34.3189 68.4573 27.2504C68.4573 23.4856 66.8182 20.1051 64.2187 17.7617H64.2059Z" fill="#C3CEFF"/>
    <path d="M55.6397 41.0289C48.0462 41.0289 41.874 34.8568 41.874 27.2632C41.874 19.6697 48.0462 13.4976 55.6397 13.4976C63.2332 13.4976 69.4054 19.6697 69.4054 27.2632C69.4054 34.8568 63.2332 41.0289 55.6397 41.0289ZM55.6397 15.4184C49.109 15.4184 43.7948 20.7325 43.7948 27.2632C43.7948 33.7939 49.109 39.1081 55.6397 39.1081C62.1704 39.1081 67.4846 33.7939 67.4846 27.2632C67.4846 20.7325 62.1704 15.4184 55.6397 15.4184Z" fill="#081E42"/>
    <path d="M86.7562 16.4049H81.6341C81.1091 16.4049 80.6737 15.9695 80.6737 15.4445C80.6737 14.9195 81.1091 14.4841 81.6341 14.4841H86.7562C87.2812 14.4841 87.7166 14.9195 87.7166 15.4445C87.7166 15.9695 87.2812 16.4049 86.7562 16.4049Z" fill="#081E42"/>
    <path d="M84.1953 18.966C83.6702 18.966 83.2349 18.5306 83.2349 18.0056V12.8835C83.2349 12.3585 83.6702 11.9231 84.1953 11.9231C84.7203 11.9231 85.1557 12.3585 85.1557 12.8835V18.0056C85.1557 18.5306 84.7203 18.966 84.1953 18.966Z" fill="#081E42"/>
    <path d="M31.681 11.2829H26.5589C26.0339 11.2829 25.5985 10.8475 25.5985 10.3225C25.5985 9.79744 26.0339 9.36206 26.5589 9.36206H31.681C32.206 9.36206 32.6414 9.79744 32.6414 10.3225C32.6414 10.8475 32.206 11.2829 31.681 11.2829Z" fill="#081E42"/>
    <path d="M29.1199 13.8439C28.5949 13.8439 28.1595 13.4086 28.1595 12.8835V7.76142C28.1595 7.2364 28.5949 6.80103 29.1199 6.80103C29.645 6.80103 30.0803 7.2364 30.0803 7.76142V12.8835C30.0803 13.4086 29.645 13.8439 29.1199 13.8439Z" fill="#081E42"/>
    <path d="M16.3147 21.527H11.1926C10.6676 21.527 10.2322 21.0916 10.2322 20.5666C10.2322 20.0416 10.6676 19.6062 11.1926 19.6062H16.3147C16.8397 19.6062 17.2751 20.0416 17.2751 20.5666C17.2751 21.0916 16.8397 21.527 16.3147 21.527Z" fill="#081E42"/>
    <path d="M13.7536 24.0881C13.2286 24.0881 12.7932 23.6527 12.7932 23.1277V18.0056C12.7932 17.4805 13.2286 17.0452 13.7536 17.0452C14.2786 17.0452 14.714 17.4805 14.714 18.0056V23.1277C14.714 23.6527 14.2786 24.0881 13.7536 24.0881Z" fill="#081E42"/>
  </SvgIcon>);
};
export default SeoSitemapEmptyTableIcon;
